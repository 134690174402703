/*
 * Divers class génériques de mise en forme
 */

// Pr tester les font-size
.is14px
  font-size: 14px
.is1-4rem
  font-size: 1.4rem
.is1-4em
  font-size: 1.4em

//WHITE SPACE
.is-nowrap
  white-space: nowrap
.is-wrap-normal
  white-space: normal

// Gestion margin/padding à volonté
$attr-positions: ('xy': '','top': '-top', 'bottom': '-bottom', 'left': '-left', 'right': '-right')

// Suppression marge
@each $margin-position, $position in $attr-positions
  .is-marginless-#{$margin-position}
    margin#{$position}: 0 !important
  .is-paddingless-#{$margin-position}
    padding#{$position}: 0 !important

//AJOUT DE MARGE & PADDING PAR ELEMENT
*,
.columns.is-gapless >
  .is-margin-auto
    margin: auto
  @each $margin-position, $position in $attr-positions
    .is-margin-#{$margin-position}
      margin#{$position}: 2rem !important
    .is-padding-#{$margin-position}
      padding#{$position}: 2rem !important
    .is-margin-#{$margin-position}-small
      margin#{$position}: 1.5rem !important
    .is-padding-#{$margin-position}-small
      padding#{$position}: 1.5rem !important
    .is-margin-#{$margin-position}-xsmall
      margin#{$position}: 1rem !important
    .is-padding-#{$margin-position}-xsmall
      padding#{$position}: 1rem !important
    .is-margin-#{$margin-position}-xxsmall
      margin#{$position}: 0.5rem !important
    .is-padding-#{$margin-position}-xxsmall
      padding#{$position}: 0.5rem !important
    .is-margin-#{$margin-position}-large
      margin#{$position}: 2rem !important
    .is-padding-#{$margin-position}-large
      padding#{$position}: 2rem !important
    .is-margin-#{$margin-position}-xlarge
      margin#{$position}: 2.5rem !important
    .is-padding-#{$margin-position}-xlarge
      padding#{$position}: 2.5rem !important

//Borders
.is-borderless
  border: 0 !important

@each $border-position, $position in $attr-positions
  .is-border-#{$border-position}
    border#{$position}: 1px solid $border !important
    &.is-border-dashed
      border#{$position}-style: dashed !important
    &.is-border-dotted
      border#{$position}-style: dotted !important

.is-border
  border: 1px solid $border !important
  &.is-border-dashed
    border-style: dashed !important
  &.is-border-dotted
      border-style: dotted !important

/*
 * TEXTE typographie
 */

// AJOUT SOULIGNEMENT
.is-hover-underline-dotted:hover
  @extend .is-underline
  @extend .is-underline--dotted
.is-hover-underline:hover
  @extend .is-underline

.is-underline
  text-decoration-line: underline
.is-underline--dotted
  text-decoration-style: dotted
  text-underline-position: under
a.is-underline,
a.is-underline--dotted
  &:hover
    text-decoration: none

// Tronque les textes et ajoute "..."
.truncate
  display: table
  table-layout: fixed
  width: 100%
  white-space: nowrap
  -webkit-line-clamp: 2
  > *
    display: table-cell
    overflow: hidden
    text-overflow: ellipsis
  abbr
    text-decoration: none

// ALIGEMENT
.is-self-stretched
  align-self: stretch
.is-self-centered
  align-self: center
.is-space-between
  justify-content: space-between
.is-align-items-flex-start
  align-items: flex-start
.is-align-items-flex-end
  align-items: flex-end

/*
 * Width && Height Helpers
 */

/* blocks widths (percentage and pixels) */
$i: 100
@while $i > 0
  .w#{$i}
    width: $i * 1%
  $i: $i - 1

$i: 1000
@while $i > 0
  .is-w#{$i}p
    width: $i * 1px
  .is-minW#{$i}p
    min-width: $i * 1px !important
  .is-maxW#{$i}p
    max-width: $i * 1px !important
  $i: $i - 2

.is-wAuto
  width: auto !important

.is-wMaxContent
  width: max-content !important

// FIXED HEIGHT
.is-height-full
  height: 100%

// MIN HEIGHT
.is-minH100
  min-height: 100%
.is-minH75p
  min-height: 75px
.is-minH64p
  min-height: 64px
.is-minH50p
  min-height: 50px


.modal .modal-card-body .content p
  white-space: pre-line
