@charset "utf-8"

/*
 * Surcharge du composant TABS de bulma
 */
.tabs
  li
    @each $name, $pair in $colors // AJOUT VADIMM
      $color: nth($pair, 1)
      $color-invert: nth($pair, 2)
      &.is-#{$name}
        a
          border-bottom-color: $color
          color: $tabs-link-active-border-bottom-color
          // border-bottom-width: $tabs-link-active-border-bottom-width
          // padding: $tabs-link-hover-padding
          background-color: $white
  // Separe d'une bordure les onglets
  &.is-vadimmSeparated
    li
      & + li
        position: relative
        &:before
          display: block
          content: ''
          position: absolute
          left: 0
          width: 1px
          top: 0.85em
          bottom: 0.85em
          background-color: $border
          z-index: 1
    &.is-fullwidth // CRM IMMO
      li
        &:first-child:last-child
          max-width: 50% // Si un seul item avec les onglets en fullwidth alors on utilise seulement 50% de la largeur
  &.is-vadimmSeparated--fullHeight
    @extend .is-vadimmSeparated
    li
      & + li
        &:before
          top: 0
          bottom: -$tabs-border-bottom-width
  li
    &.is-disabled
      a
        cursor: not-allowed
        color: $light
        // opacity: 0.7
        // filter: grayscale(33%)
        &:hover
          border-color: transparent
