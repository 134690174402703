/*
 * Surcharge du composant Modal de Bulma
 */

$modal-xlarge-content-width: 1240px !default
$modal-large-content-width: 960px !default
$modal-medium-content-width: 480px !default
$modal-small-content-width: 320px !default

.modal
  .modal-card-head
    // .modal-card-title

  // CRM IMMO
  // .modal-card-body
  .modal-card-body + .modal-card-body
    border-top: 1px solid darken($border, 5%)
    box-shadow: inset 0 16px 16px -16px rgba(95, 95, 95, 0.4)
  .modal-card
    position: relative // CRM IMMO

  &.is-modal-stickTop // CRM IMMO (modifieur créé)
    align-items: flex-start
    justify-content: flex-start
    padding-top: $modal-content-spacing-mobile / 2
    // Responsiveness
    +tablet
      padding-top: $modal-content-spacing-tablet / 2

.modal-content,
.modal-card
  // Responsiveness
  +tablet
    width: $modal-content-width
    .is-modal-xlarge & // CRM IMMO
      width: $modal-xlarge-content-width
    .is-modal-large & // CRM IMMO
      width: $modal-large-content-width
    .is-modal-medium & // CRM IMMO
      width: $modal-medium-content-width
    .is-modal-small & // CRM IMMO
      width: $modal-small-content-width
