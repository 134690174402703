// Roboto

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-Regular.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-Regular.woff') format('woff')
    font-weight: normal
    font-style: normal

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-Light.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-Light.woff') format('woff')
    font-weight: 300
    font-style: normal

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-MediumItalic.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-MediumItalic.woff') format('woff')
    font-weight: 500
    font-style: italic

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-Italic.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-Italic.woff') format('woff')
    font-weight: normal
    font-style: italic

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-Bold.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-Bold.woff') format('woff')
    font-weight: bold
    font-style: normal

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-BoldItalic.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-BoldItalic.woff') format('woff')
    font-weight: bold
    font-style: italic

@font-face
    font-family: 'Roboto'
    src: url('#{$VADIMM-font-path}/Roboto-Medium.woff2') format('woff2'), url('#{$VADIMM-font-path}/Roboto-Medium.woff') format('woff')
    font-weight: 500
    font-style: normal
