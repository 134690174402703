/*
 * Page de login
 */
$loginPadding-h: 2.5rem
// $loginPadding-h--mobile: 1.5rem
$loginMaxWidth: 106.0rem
$loginMaxWidth-noUnit: 106

.l-login
  max-width: $loginMaxWidth
  margin: 0 auto
  padding: 1rem
  border: 1px solid $border
  background-color: $white
  +mobile
    padding-left: 0
    padding-right: 0

.login-header
  display: flex
  +mobile
    flex-direction: column
.login-header-leftCol
  width: (((27.4 + 1) * 100) / $loginMaxWidth-noUnit) * 1%
  min-width: 20rem
  flex: none
  display: flex
  flex-direction: column
  +mobile
    min-width: 0
    width: auto
.login-header-rightCol
  flex: 1 1 auto
  padding-left: $loginPadding-h
  padding-right: $loginPadding-h
  padding-bottom: 2rem
  margin-top: 3.5rem
  p:not(:last-child)
    margin-bottom: 1.5rem
.login-logo
  flex: none
  .image
    margin: 0 auto
    max-width: 17.8rem
.login-illustration
  flex: 1 1 auto
  background-position: center
  background-size: 100% auto
  background-size: cover
  background-repeat: no-repeat
  +mobile
    position: relative
    padding-top: 40.635% // 640 * 260
    background-position: center 10%

.login-title
  color: $primary
.login-description
  font-size: $size-6
 

.login-content
  display: flex
  +mobile
    flex-direction: column
.login-content-leftCol
  width: (((37 + 1) * 100) / $loginMaxWidth-noUnit) * 1%
  min-width: 34rem
  flex: none
  background-color: $primary
  display: flex
  align-items: center
  +mobile
    min-width: 0
    width: auto
.login-content-rightCol
  flex: 1 1 auto
  display: flex
  justify-content: center
  align-items: center
  padding: $loginPadding-h
  background-color: $body-background-color
  border: 1px solid $border

.login-cta
  color: $white
  padding: 2.5rem 4.5rem
  background-color: $primary
  .title,
  .subtitle
    color: $white
  p
    line-height: 1.1
  +mobile
    padding-left: $loginPadding-h
    padding-right: $loginPadding-h


.login-form
  display: block
  max-width: 52.2rem
  margin: auto
  
.login-form-title
  color: $primary

.login-footer
  padding: 1.5rem 1.75rem 0.5rem 1.75rem
  +mobile
    padding-left: $loginPadding-h
