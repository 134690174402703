.home-container
  // margin-left: 0 !important
  // margin-right: 0 !important
  // margin-top: 0 !important
  // margin-bottom: 0 !important
  margin:0 !important


.cover-image
  height: 100vh

.email-field
  padding-right: 3rem 

.is-multiline
  padding: 0 !important
  margin:0 !important

.logo-container
  display: inline
  // margin-top: 20rem
  margin-top: auto

.logo-container-overlay
  display: none

.logo
  width: 250px

.is-padding
  padding: 2rem

.is-padding-right
  padding-right: 3.5rem

.login-button, .create-account-button
  border-radius: 5px
  padding-left: 5rem
  padding-right: 5rem

.cover-image
  background-repeat: no-repeat
  background-size: cover

@media screen and ( max-width: 768px )
  .cover-image
    height: 30vh
  
  .logo-container
    margin-top: 0rem
    position: absolute
    display: none
  
  .logo-container-overlay
    display: inline
    position: absolute
    right: 0
    left: 0
    margin-left: auto
    margin-right: auto
    width: 40%
    background-color: white
    padding: 1rem