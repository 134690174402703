/*
 * Page registration
 */

/*
 * Page de login
 */
$registerPadding-h: 2.5rem
$registerMaxWidth: 78.6rem

.l-register
  max-width: $loginMaxWidth
  margin: 0 auto
  padding: 1.5rem 1rem
  border: 1px solid $border
  background-color: $white
  +mobile

.register-logo
  .image
    margin: 0 auto 4.2rem auto
    max-width: 17.8rem

.register-form
  max-width: 59.2rem
  margin: 0 auto
  .field
    .label
      font-weight: $weight-medium

.register-form-title
  margin-top: 2rem
  color: $primary


.register-form-submit
  margin-top: 4.5rem

.register-form-footer
  margin-top: $registerPadding-h