/*
 * Surcharge de l'élément button
 */

// CRM Immo ajout de la taille xsmall
=button-xsmall
  font-size: $size-xsmall // CRM IMMO
  // height: 2.65em // CRM IMMO

.button
  // CRM Immo ajout de la taille xsmall
  &.is-xsmall
    +button-xsmall

  // CRM IMMO ajout de "isSquare"
  &.is-square
    min-width: 0
    width: $control-height
    padding-left: 0
    padding-right: 0

  // CRM IMMO ajout de "isCircle"
  &.is-circle
    @extend .is-square
    border-radius: 2000px
    // padding-left: 0.25em
    // padding-right: 0.25em

  @each $name, $pair in $colors
    $color: nth($pair, 1)
    $color-invert: nth($pair, 2)

    &.is-#{$name}
      @if $name == 'success' // CRM IMMO
        &:not(.is-outlined):not(.is-inverted)
          @extend .is-lGradient
      &.is-striped // CRM IMMO
        background-image: repeating-linear-gradient(-45deg, transparent, transparent 7px, rgba(255,255,255,.115) 7px, rgba(255,255,255,.115) 8px)
      // Ajout CRM Immo
      &.is-lGradient
        $bg1: lighten($color, 3%)
        $bg2: darken($color, 2%)
        $bg3: darken($color, 6%)
        background-image: linear-gradient(to bottom, $bg1, $bg2 50%, $bg3 100%)
        transition: background-image 0.01s ease-in // Don't work on FF
        border-color: darken($color, 6%)
        &:hover,
        &.is-hovered
          $bg1: lighten($color, 3%)
          $bg2: lighten($color, 2%)
          $bg3: lighten($color, 6%)
          background-image: linear-gradient(to bottom, $bg1, $bg2 50%, $bg3 100%)
        // &:focus,
        // &.is-focused

        &:active,
        &.is-active
          $bg1: lighten($color, 1%)
          $bg2: darken($color, 4%)
          $bg3: darken($color, 8%)
          background-image: linear-gradient(to bottom, $bg1, $bg2 50%, $bg3 100%)
        &[disabled]
          background-image: linear-gradient(to bottom, $bg1, $bg2 50%, $bg3 100%)
