/*
 * Bulma initial variables
 */

// Colors

$black:        hsl(0, 0%, 4%) !default
$black-bis:    hsl(0, 0%, 7%) !default
$black-ter:    hsl(0, 0%, 14%) !default

$grey-darker:  hsl(0, 0%, 21%) !default
$grey-dark:    hsl(0, 0%, 29%) !default
$grey:         hsl(0, 0%, 48%) !default
$grey-light:   hsl(0, 0%, 71%) !default
$grey-lighter: hsl(0, 0%, 86%) !default
$grey-lightest: hsl(0, 0%, 97%)  // Ajout VADIMM

$white-ter:    hsl(0, 0%, 96%) !default
$white-bis:    hsl(0, 0%, 98%) !default
$white:        hsl(0, 0%, 100%) !default

$orange:       hsl(32,  93.7%, 76.5%) // hsl(14,  100%, 53%) !default
$orange-2:     rgb(248, 112, 81) // Créer et utlisé la première fois pr une tuile annonce (30/07/2018)
$yellow:       hsl(48,  100%, 67%) !default
$green:        rgb(128, 188, 116) // hsl(141, 71%,  48%) !default
$turquoise:    hsl(171, 100%, 41%) !default
$cyan:         hsl(204, 86%,  53%) !default
$blue:         hsl(217, 71%,  53%) !default
$blue-2:       rgb(31, 50, 107)
$purple:       hsl(271, 100%, 71%) !default
$red:          rgb(219, 83, 77) // hsl(348, 100%, 61%) !default
$brown:        rgb(188, 175, 167) // Site public
$peche :       rgb(249, 226, 215) // HB : Introduit lors de la refonte adv (05/19, sur version avant saas)

$cGoogleAgenda: rgb(66, 133, 244)
$cAllotement: rgb(165, 137, 140)

// Typography

$family-sans-serif: "Roboto", BlinkMacSystemFont, -apple-system, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif

$size-xl: 3.2rem // CRM IMMO
$size-l: 2.8rem // CRM IMMO
$size-1: 2.6rem
$size-2: 2.2rem
$size-3: 1.8rem
$size-4: 1.6rem
$size-5: 1.4rem
$size-6: 1.2rem
$size-7: 1.05rem

$weight-light: 300
$weight-normal: 400
$weight-medium: 500
// $weight-semibold: 600 // Attention, la font size n'est pas chargé
$weight-bold: 700

// Spacing

$block-spacing: 2rem // 1.5rem !default

/*
 * Bulma derived variables
 */

$primary: rgb(11, 36, 77) // $turquoise !default

$info: $cyan !default
$success: $green
$warning: $peche // HB : anciennement $orange
$danger: $red !default

$light: $white-ter
$dark: $grey-darker !default

// Invert colors
$primary-invert: $white
$peche-invert: findColorInvert($peche) !default

// General colors

// $background: $white-ter !default

$border: $grey-lighter // $grey-lighter !default
$border-hover: $grey-light

// Text colors
$text: $grey-dark

$custom-colors: ("rdv": ($blue-2, $white), "waiting_list": (rgb(251, 199, 139), $white), "annonce": ($orange-2, $white), "google_agenda": ($cGoogleAgenda, $white), "notification": ($orange-2, $white), "allotement": ($cAllotement, $white))

// VADIMM : Couleurs des status
$status-colors: ("available": (rgb(92, 197, 186), $white), "optioned": (rgb(242, 208, 84), $text), "reserved": (rgb(38, 102, 180), $white), "sold": (rgb(6, 8, 17), $white), "draft": (rgb(190, 190, 190), $white), "approved": ($success, $white), "pre_reserved": (rgb(92, 180, 235), $white), "pending": (rgb(141, 149, 229), $white))
// VADIMM : Couleurs des POI
$poi-colors: ("acSortie": (rgb(240, 126, 184), $white), "acCommerce": (rgb(253, 184, 42), $white), "acEcole": (rgb(161, 134, 101), $white), "acTransport": (rgb(10, 165, 205), $white),"acSante": (rgb(66, 179, 110), $white))
// VADIMM : On merge le tout dans "$custom-colors" qui sera ensuite utilisé par BULMA et le theme
$custom-colors: map-merge($custom-colors, $status-colors)
$custom-colors: map-merge($custom-colors, $poi-colors)

// Link colors

$link: $primary
$link-invert: $primary-invert
$link-visited: $primary

$link-hover: darken($primary, 8%)
$link-hover-border: lighten($primary, 8%)

// Typography

// $family-primary: $family-sans-serif !default
// $family-code: $family-monospace !default

$size-xsmall: $size-7
$size-small: $size-6
$size-normal: $size-5
$size-medium: $size-4
$size-large: $size-3

$sizes: $size-1 $size-2 $size-3 $size-4 $size-5 $size-6 $size-7

// Responsiveness

// The container horizontal gap, which acts as the offset for breakpoints
$gap: 32px !default
// 960, 1152, and 1344 have been chosen because they are divisible by both 12 and 16
$tablet: 769px !default
// 960px container + 4rem
$desktop: 960px + (2 * $gap) !default
// 1152px container + 4rem
$widescreen: 1152px + (2 * $gap) !default
$widescreen-enabled: true !default
// 1344px container + 4rem
$fullhd: 1344px + (2 * $gap) !default
$fullhd-enabled: true !default

/*
 * Variables du projet (non bulma)
 * prefixées par VADIMM-
 */

/* 
 * Vendor (Bulma + autre libs)
 */

$VADIMM-font-path: "../fonts"
// FontAwesome
$fa-font-path: $VADIMM-font-path

//Header
$VADIMM-header-navbar-hauteur : 8.2rem // Header navigation hauteur

// Page Header
$VADIMM-page-header-btn-h: 4.9rem
$VADIMM-page-header-btn-square-w: 4.9rem
$VADIMM-margin-align-on--page-header-btn-square: $VADIMM-page-header-btn-square-w // Pour créer des goutières aligner sur ce standard

// sidebarNavigation
$VADIMM-sidebarNavigation-largeur : 9.9rem !default// Barre de navigation sur la gauche du site
$VADIMM-sidebarNavigation-sub-largeur : 30rem !default // Largeur du sous menu de la barre de nav


// Filtrage (colonne de gauche)
$VADIMM-widthColFiltre: 28.1rem
/*
 * Vendor (Bulma + autre libs)
 */

// FontAwesome
$fa-font-path: $VADIMM-font-path

// Update some of Bulma's component variables
// Bulma generic.sass
$body-background-color: rgb(247, 248, 250) // $grey-lightest
$body-overflow-x: hidden
$body-overflow-y:  hidden
$body-size: $size-normal

// DropDown
$dropdown-content-padding-bottom: 0.75rem
$dropdown-content-padding-top: 0.75rem
$dropdown-content-offset: 7px
$dropdown-content-radius: 0
$dropdown-content-shadow: 0 0 20px rgba(0, 0, 0, 0.2)

$dropdown-item-color: $grey
$dropdown-item-hover-color: $primary
$dropdown-item-hover-background-color: $light
$dropdown-item-active-color: $primary
$dropdown-item-active-background-color: transparent

// Image.sass
$dimensions: 16 24 32 48 64 96 128 200 256 !default

// Navbar
// $navbar-breakpoint: $tablet

// Title
$title-weight: $weight-medium

// Icon
$icon-dimensions: 1.75rem
$icon-dimensions-small: 1.2rem
$icon-dimensions-medium: 2.2rem
$icon-dimensions-large: 3.2rem

// Bulma form/tools.sass
$label-color: $grey
$label-weight: $weight-normal

// Bulma Tabs.sass
$tabs-link-active-border-bottom-color: $dark
$tabs-link-active-color: $text
$tabs-border-bottom-width: 3px
$tabs-border-bottom-color: transparent
$tabs-link-padding: 0.95em 1em
$tabs-link-color: $grey

// Bulma modal.sass
$modal-z: 100
$modal-background-background-color: rgba($primary, 0.78) // rgba(255, 255, 255, 0.86) (test en blanc)
$modal-card-head-background-color: $white
$modal-card-head-radius: 0
$modal-card-head-padding: 1.5rem $block-spacing
$modal-card-foot-radius: 0

// Bulma form/shared.sass
$input-border-color: $border
$input-hover-border-color: $grey-light !default

// $input-focus-color: $grey-darker !default
// $input-focus-border-color: $primary
$input-shadow: inset 0 1px 2px rgba($black, 0.04)

$input-arrow: $dark

$input-radius: 0

// $input-focus-color: $grey-darker !default
$input-focus-border-color: $dark
$input-focus-box-shadow-size: 0 0 0 0.1em
$input-focus-box-shadow-color: rgba($dark, 0.075)

// $label-color: $grey-light

// Button.sass
$button-border-color: $border

// Control
$control-height: 3em
// $control-border-width: 1px
// $control-padding-vertical: calc(0.475em - #{$control-border-width})

// Bulma table.sass
$table-head-cell-border-width: 0 0 1px

// Bulma menu.sass
$menu-item-radius: 0
$menu-item-hover-background-color: darken($body-background-color, 5%)
$menu-label-color: $text

// Bulma-Badge (extension de bulma)
$badge-size-small: 1.35rem
$badge-size-normal: 1.6rem
$badge-size-medium: 1.8rem
$badge-size-large: 2rem

// Bulma-quickview
$quickview-header-height: calc($VADIMM-header-navbar-hauteur / 1.5)
$quickview-footer-height: $VADIMM-header-navbar-hauteur
$quickview-shadow: 5px 10px 13px 3px rgba( 0, 0, 0, 0.1 )

// Notification (native Bulma)
$notification-radius: 0
$notification-padding: 1.25rem $block-spacing 1.25rem $block-spacing

/* SweetAlert (swal) */

// BOX MODEL
$swal2-width: 50rem
$swal2-border-radius: 0
$swal2-padding: 1.5rem 0 1.5rem

// TYPOGRAPHY
$swal2-font-size: $size-5

// BACKDROP
$swal2-backdrop: rgba(200, 200, 200, 0.55)

// TITLE
$swal2-title-margin: 0 1.5rem 1.5rem
$swal2-title-color: $primary
$swal2-title-font-size: $size-3

// CONTENT
$swal2-content-pading: 1.5rem 1.5rem 0 1.5rem
$swal2-content-color: $text
$swal2-content-font-size: $size-normal

// ANIMATIONS
$swal2-show-animation: none
$swal2-hide-animation: none

// CLOSE BUTTON
// $swal2-close-button-width: 1.28rem
// $swal2-close-button-height: 1.28rem
$swal2-close-button-line-height: 1.28
$swal2-close-button-color: $dark
$swal2-close-button-font-size: 1.8em

// CLOSE BUTTON:HOVER
$swal2-close-button-hover-color: darken($dark, 5%)

// CONFIRM BUTTON
$swal2-confirm-button-border-radius: 0
$swal2-confirm-button-background-color: $primary 
$swal2-confirm-button-font-size: $size-5

// CANCEL BUTTON
$swal2-cancel-button-border-radius: 0
// $swal2-cancel-button-background-color: #aaa !default
// $swal2-cancel-button-color: $swal2-white !default
$swal2-cancel-button-font-size: $size-5

// Switch extension de bulma
$switch-focus: 0
