/* switching to border-box model for all elements */
html
  box-sizing: border-box
  background-color: #FFFFFF

*,
*::before,
*::after
  box-sizing: inherit
  /* avoid min-width: auto on flex and grid children */
  min-width: 0
  min-height: 0

html
  /* set base font-size to equiv "10px", which is adapted to rem unit */
  font-size: 62.5%
  /* IE9-IE11 math fixing. See http://bit.ly/1g4X0bX */
  font-size: calc(1em * 0.625)
  overflow-y: auto

body
  font-size: $body-size

a
  &.is-underlined
    text-decoration: underline
    &:hover
      text-decoration: none
